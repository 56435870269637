<template>
    <div class="cs-image-slider-block cs-block" :class="`${styleClass}${hasBg}`">
        <div class="cs-block-base">
            <div class="container">
                <div class="header-content">
                    <h2 class="cs-title" v-if="data.title">
                        <ColorSplit :data="data.title" />
                    </h2>
                    <h3 class="cs-sub-title" v-if="data.subTitle">
                        {{ data.subTitle }}
                    </h3>
                    <span v-if="data.text" class="cs-text" v-html="data.text"/>
                </div>
                <Slider :data="data.items" v-slot="item" :slidesToShow="1" :hasArrow="data.hasArrows">
                    <CSButton :data="item.slide.url" class="slide-link">
                        <img
                            :src="item.slide.image"
                            class="slider__item-image"
                        />
                        <div class="slide-content">
                            <h6 v-if="item.slide.title">
                                {{ item.slide.title }}
                            </h6>
                            <span v-if ="item.slide.text" class="cs-text" v-html="item.slide.text"></span>
                        </div>
                    </CSButton>
                </Slider>
            </div>
        </div>
    </div>
</template>

<script>

import {computed} from '../libs/common-fn';

export default {
    name: 'ImageSlider',
    components: { 
        ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
        Slider: () => import("../blocks_components/Slider.vue"),
        CSButton: () => import("../components/CSButton.vue"),
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {};
    },
    methods: {},
    computed: {
        ...computed('ImageSlider'),
        hasBg() {
            if (this.data.style == 'color') return ' has-background';

            return '';
        }
    }
};
</script>

<style scoped lang="scss">
@import '../styles/main.scss';
.cs-block.cs-image-slider-block {
    &.cs-style- {
        &color {
            @include cs-block-color;
            .cs-sub-title{
                color:$sub_title_color;
            }
            *, ::v-deep *{
                color:white;
            }

            /* Such color used because it is meant that image will be contrasting with background of block but in this block with color style white color fits better */
            .cs-block-base {
                .container {
                    .header-content {
                        > .cs-text {
                            color: $text_color !important;

                            ::v-deep {
                                * {
                                    color: $text_color !important;
                                }
                            }
                        }
                    }
                }
            }
            ::v-deep {
                .slide-content {
                    .cs-text {
                        * {
                            color: $text_color !important;
                        }

                        p,
                        span {
                            color: $text_color !important;
                        }
                    }
                }
            }
        }

        &light {
            @include cs-block-light;
            .cs-sub-title{
                color: $sub_title_color_light;
            }

            /* Such color used because it is meant that image will be contrasting with background of block, so since color of block text is light, text in slide goes dark */
            ::v-deep {
                .slide-content {
                    .cs-text {
                        * {
                            color: $text_color_dark !important;
                        }

                        p,
                        span {
                            color: $text_color_dark !important;
                        }
                    }
                }
            }
        }

        &dark {
            @include cs-block-dark;
            .cs-sub-title{
                color: $sub_title_color_dark;
            }

            /* Such color used because it is meant that image will be contrasting with background of block, so since color of block text is light, text in slide goes dark */
            ::v-deep {
                .slide-content {
                    .cs-text {
                        * {
                            color: $text_color_dark !important;
                        }

                        p,
                        span {
                            color: $text_color_dark !important;
                        }
                    }
                }
            }
        }
    }
    .header-content {
        text-align: center;
        margin-bottom: $header_margin;
        &:last-child {
            margin-bottom: 0;
        }
        .cs-title, .cs-sub-title {
            margin-bottom: 20px;
        }
        .cs-text {
            ::v-deep {
                * {
                    text-align: center;
                }
                p,
                span {
                    @include text;
                }
                *:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

::v-deep {

    .slider-item{
        padding: 0 !important;

        .cs-button{
            cursor: pointer;
            position: relative;
            display: flex;
            text-decoration: none;
            border: 1px solid #7d98b5;
            background: #ffffff57;
            backdrop-filter: blur(25px);
            border-radius: 20px;
            padding: 10px;
        }
        .slide-content {
            position: absolute;
            bottom: 80px;
            left: 0; 
            right: 0; 
            margin-left: auto; 
            margin-right: auto; 
            padding: 0px 80px;

            h6 {
                @include title;
                font-size: 20px;
                color: #fff;
            }
            span {
                color: #fff;

                p {
                    @include text;
                    color: #fff;
                }
            }
        }

        .slider__item-image {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            height: 550px;
            border-radius: 20px;
            object-fit: cover;
        }
        
	}
}

</style>
